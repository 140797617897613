import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import Root from "./views/Root";

import "./styles/index.scss";
import { AppContext } from "./lib/context";

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AppContext.Provider value={{ portalTarget: document.getElementById("portal-target") as HTMLDivElement }}>
      <Root />
    </AppContext.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
